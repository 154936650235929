import { BrowserRouter, Route, Routes } from "react-router-dom"
import Historico from "./pages/Sintomas/Historico"
import SintomasPrincipais from "./pages/Sintomas/Principais"
import PageDados from "./pages/Dados"
import PageSuccesso from "./pages/Sucesso/indes"
import OutrosSintomas from "./pages/Sintomas/Outros"
import ScrollTop from "./hooks/ScrollTop"
import Homepage from "./pages/Home"
import PagePoliticaDePrivacidade from "./pages/PoliticaDePrivacidade"

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <ScrollTop />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/historico" element={<Historico />} />
        <Route path="/principais-sintomas" element={<SintomasPrincipais />} />
        <Route path="/outros-sintomas" element={<OutrosSintomas />} />
        <Route path="/informar-dados" element={<PageDados />} />
        <Route path="/sucesso" element={<PageSuccesso />} />
        <Route
          path="/politica-de-privacidade"
          element={<PagePoliticaDePrivacidade />}
        />
      </Routes>
    </BrowserRouter>
  )
}
