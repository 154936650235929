import { Link } from "react-router-dom"
import Board from "../../components/Board"
import Header from "../../components/Header"
import Main from "../../components/Main"
import SectionTitle from "../../components/SectionTitle"
import { FaHouse } from "react-icons/fa6"

export default function PagePoliticaDePrivacidade() {
  return (
    <Main>
      <Header>Política de privacidade</Header>
      <Board>
        <SectionTitle>
          Política de Privacidade de Uso de Dados do Dr. Kleiner Pinheiro
        </SectionTitle>
        <div className="my-8 text-slate-700">
          <h2 className="font-bold mb-2 text-lg">Introdução:</h2>
          <p className="font-medium">
            Bem-vindo ao site do Dr. Kleiner Pinheiro. Estamos comprometidos em
            proteger a privacidade dos nossos usuários. Esta Política de
            Privacidade explica como coletamos, usamos, divulgamos e protegemos
            suas informações pessoais. Ao utilizar nosso site, você concorda com
            as práticas descritas nesta política.
          </p>
        </div>
        <div className="text-slate-700 my-8 font-medium">
          <h2 className="text-lg mb-2 font-bold">1. Coleta de Informações:</h2>
          <p>
            Coletamos informações de diversas maneiras para fornecer e melhorar
            nossos serviços. As informações que podemos coletar incluem:
          </p>
          <ul className="my-4">
            <li className="my-2 list-disc ml-4">
              Informações Pessoais: Nome, endereço de e-mail, número de telefone
              e outras informações que você nos fornece voluntariamente ao
              entrar em contato conosco, preencher formulários ou se registrar
              em nosso site.
            </li>
            <li className="my-2 list-disc ml-4">
              Informações de Navegação: Dados coletados automaticamente quando
              você visita nosso site, como endereço IP, tipo de navegador,
              páginas visitadas, tempo de permanência no site e dados de
              cookies.
            </li>
          </ul>
        </div>
        <div className="text-slate-700 my-8 font-medium">
          <h2 className="text-lg mb-2 font-bold">2. Uso das Informações:</h2>
          <p>
            Utilizamos as informações coletadas para diversos fins, incluindo:
          </p>
          <ul className="my-4">
            <li className="my-2 list-disc ml-4">
              Fornecimento de Serviços: Para responder às suas perguntas,
              processar suas solicitações e fornecer os serviços que você
              solicitou.
            </li>
            <li className="my-2 list-disc ml-4">
              Melhoria do Site: Para analisar e entender como nosso site é
              usado, permitindo-nos melhorar seu funcionamento e experiência do
              usuário.
            </li>
            <li className="my-2 list-disc ml-4">
              Comunicações: Para enviar comunicações, como newsletters,
              atualizações e informações sobre serviços, desde que você tenha
              consentido em recebê-las.
            </li>
            <li className="my-2 list-disc ml-4">
              Segurança: Para proteger nossos usuários e prevenir atividades
              fraudulentas ou não autorizadas.
            </li>
          </ul>
        </div>
        <div className="text-slate-700 my-8 font-medium">
          <h2 className="text-lg mb-2 font-bold">
            3. Compartilhamento de Informações:
          </h2>
          <p>
            Não vendemos, trocamos ou alugamos suas informações pessoais para
            terceiros. Podemos compartilhar informações com terceiros apenas nas
            seguintes circunstâncias:
          </p>
          <ul className="my-4">
            <li className="my-2 list-disc ml-4">
              Provedores de Serviços: Podemos compartilhar informações com
              provedores de serviços terceirizados que nos ajudam a operar nosso
              site e fornecer nossos serviços, desde que esses provedores
              concordem em manter essas informações confidenciais.
            </li>
            <li className="my-2 list-disc ml-4">
              Obrigação Legal: Podemos divulgar informações se exigido por lei
              ou em resposta a uma solicitação legal válida, como uma ordem
              judicial ou intimação.
            </li>
          </ul>
        </div>
        <div className="text-slate-700 my-8 font-medium">
          <h2 className="text-lg mb-2 font-bold">
            4. Proteção das Informações:
          </h2>
          <p>
            Adotamos medidas de segurança apropriadas para proteger suas
            informações pessoais contra acesso não autorizado, alteração,
            divulgação ou destruição. No entanto, nenhum método de transmissão
            pela Internet ou de armazenamento eletrônico é 100% seguro, e não
            podemos garantir a segurança absoluta de suas informações.
          </p>
        </div>
        <div className="text-slate-700 my-8 font-medium">
          <h2 className="text-lg mb-2 font-bold">5. Seus Direitos:</h2>
          <p>
            Você tem o direito de acessar, corrigir, atualizar ou excluir suas
            informações pessoais. Para exercer esses direitos, entre em contato
            conosco através das informações fornecidas abaixo.
          </p>
        </div>
        <div className="text-slate-700 my-8 font-medium">
          <h2 className="text-lg mb-2 font-bold">
            6. Cookies e Tecnologias Semelhantes:
          </h2>
          <p>
            Utilizamos cookies e tecnologias semelhantes para melhorar sua
            experiência em nosso site. Você pode optar por desativar os cookies
            através das configurações do seu navegador, mas isso pode afetar a
            funcionalidade do site.
          </p>
        </div>
        <div className="text-slate-700 my-8 font-medium">
          <h2 className="text-lg mb-2 font-bold">
            7. Alterações a Esta Política:
          </h2>
          <p>
            Podemos atualizar esta Política de Privacidade periodicamente para
            refletir mudanças em nossas práticas ou em leis aplicáveis.
            Recomendamos que você reveja esta política regularmente para se
            manter informado sobre como proteger suas informações.
          </p>
        </div>
        <div className="text-slate-700 my-8 font-medium">
          <h2 className="text-lg mb-2 font-bold">8. Contato:</h2>
          <p>
            Se você tiver alguma dúvida ou preocupação sobre esta Política de
            Privacidade, entre em contato conosco através do e-mail{" "}
            <Link
              to={"mailto:drkleiner@ingoh.com.br"}
              className="font-bold hover:underline"
            >
              drkleiner@ingoh.com.br
            </Link>{" "}
            ou pelo telefone{" "}
            <Link to={"tel:62982940708"} className="font-bold hover:underline">
              (62) 98294-0708.
            </Link>
          </p>
        </div>
        <div className="text-slate-700 my-8 font-medium">
          <h2 className="text-lg mb-2 font-bold">Conclusão:</h2>
          <p>
            Estamos comprometidos em proteger sua privacidade e suas informações
            pessoais. Ao usar nosso site, você concorda com os termos desta
            Política de Privacidade. Agradecemos sua confiança em nossos
            serviços e estamos à disposição para esclarecer qualquer dúvida.
          </p>
        </div>
        <div className="my-8">
          <Link
            to="/"
            className="inline-flex rounded px-4 py-2 items-center text-slate-700 font-bold bg-slate-400 hover:brightness-90 transition-all"
          >
            <FaHouse className="mr-1" />
            Início
          </Link>
        </div>
      </Board>
    </Main>
  )
}
