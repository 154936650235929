import { useForm } from "react-hook-form"
import Board from "../../components/Board"
import Header from "../../components/Header"
import Main from "../../components/Main"
import SectionTitle from "../../components/SectionTitle"
import Submit from "../../components/forms/submit"
import { FaAngleRight } from "react-icons/fa6"
import Text from "../../components/forms/text"
import { zodResolver } from "@hookform/resolvers/zod"
import useValidate from "../../hooks/useValidate"
import { z } from "zod"
import { Link, useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { unjson } from "../../functions/JSON"

type DadosSchema = z.infer<typeof useValidate.DadosSchema>

export default function PageDados() {
  const navigate = useNavigate()

  useEffect(() => {
    const userData = localStorage.getItem("drk-p-0")
    if (userData) {
      let user: DadosSchema = unjson(atob(userData))
      setValue("email", user.email)
      setValue("fullName", user.fullName)
      setValue("phone", user.phone)
      setValue("sexo", user.sexo)
    }
  })

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm<DadosSchema>({
    resolver: zodResolver(useValidate.DadosSchema),
  })

  const onSubmit = (d: DadosSchema) => {
    const { email, fullName, phone, sexo } = d

    localStorage.setItem(
      "drk-p-0",
      btoa(JSON.stringify({ email, fullName, phone, sexo })).replace("==", "")
    )

    navigate("/historico")
  }

  return (
    <Main>
      <Header>Questionário de Triagem para Cândida</Header>
      <Board>
        <SectionTitle>Dados pessoais</SectionTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <p>
            Responder a estas perguntas ajudará você e seu médico a decidirem se
            a CÂNDIDA pode ser parte dos seus problemas de saúde. Para cada
            seção, leia as instruções e marque conforme indicado.
          </p>

          <p className="mt-5">
            Informe os dados abaixo para iniciar o seu teste
          </p>

          <Text id="input-name" label="Nome completo:" error={errors.fullName}>
            <input type="text" {...register("fullName")} id="input-name" />
          </Text>

          <div className={`my-6`}>
            <div className="input-description mb-3">Sexo</div>
            <label
              htmlFor={"opt-masculino"}
              className={`flex items-center input-radio-unique`}
            >
              <input
                type="radio"
                {...register("sexo")}
                id={"opt-masculino"}
                value={"masculino"}
                required={true}
              />
              <span className="icon"></span>
              <span>Masculino</span>
            </label>
            <label
              htmlFor={"opt-feminino"}
              className={`flex items-center input-radio-unique`}
            >
              <input
                type="radio"
                {...register("sexo")}
                id={"opt-feminino"}
                value={"feminino"}
                required={true}
              />
              <span className="icon"></span>
              <span>Feminino</span>
            </label>
          </div>
          <Text id="input-email" label="E-mail:" error={errors.email}>
            <input type="text" {...register("email")} id="input-email" />
          </Text>
          <Text id="input-phone" label="Telefone:" error={errors.phone}>
            <input type="text" {...register("phone")} id="input-phone" />
          </Text>

          <div className="my-6">
            <label>
              <input type="checkbox" name="opt-in" id="opt-in" required />
              <span className="text-slate-700 ml-2">
                Estou ciente e de acordo com a{" "}
                <Link
                  to={"/politica-de-privacidade"}
                  className="font-bold hover:underline"
                  target="_blank"
                >
                  política de privacidade.
                </Link>
              </span>
            </label>
          </div>

          <div className="input-submit">
            <Submit>
              Responder questionário
              <FaAngleRight className="ml-2" size={16} />
            </Submit>
          </div>
        </form>
      </Board>
    </Main>
  )
}
